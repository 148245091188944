//HEADER
#header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  height: 60px;
  padding: 0 46px;
  .fixed-nav-mobile-active & {
    opacity: 0;
    pointer-events: none;
  }
  &.hide {
    opacity: 0;
    transform: translateY(-60px);
    pointer-events: none;
  }
  @media (min-width: $desktop) {
    &:hover {
      .navigation {
        ul {
          opacity: 1;
          transition: all 350ms 125ms ease;
          transform: translateX(0);
        }
        .menu-toggle-button {
          transition: opacity 350ms 125ms ease;
          opacity: 0;
          span {
            margin-right: 20px;
          }
        }
      }
    }
  }
}

#header-hover-handler {
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  height: 140px;
  background-color: transparent;
  z-index: 0;
  display: none;
  @media (min-width: $desktop) {
    display: block;
  }
}
#logo {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  a {
    color: #fff;
    font-size: 18px;
    display: block;
    outline: none;
    text-decoration: none;
    transition: all 250ms 0s ease;
    padding-top: 5px;
    &:focus {
      outline: none;
      text-decoration: none;
    }
    @media (min-width: $desktop) {
      &:hover {
        opacity: 0.8;
      }
    }
  }
  @media (min-width: $desktop) {
    img {
      height: 35px;
    }
  }
  @media (max-width: $desktop) {
    img {
      height: 25px;
    }
  }
}

.header-side {
  position: absolute;
  top: 24px;
}

.menu-toggle-button {
  height: 100%;
  background-color: transparent;
  display: flex;
  border: none;
  padding: 0;
  top: 0;
  margin: 0;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1;
  transition: all 250ms 0s ease;
  &:focus {
    outline: none;
  }
  span {
    display: block;
    width: 1px;
    height: 16px;
    background-color: #fff;
    margin-right: 8px;
    &:last-child {
      margin-right: 0;
    }
  }
}

#mobile-menu-toggle-button {
  left: 0;
  width: 60px;
  .search-active & {
    opacity: 0;
    pointer-events: none;
  }
  @media (min-width: $desktop) {
    display: none;
  }
}

#search-toggle-button {
  width: 60px;
  right: 0;
  color: #fff;
  font-size: 18px;
  @media (min-width: $desktop) {
    display: none;
  }
}

#search-close-icon {
  display: none;
}

#search-icon {
  display: block;
}

.navigation {
  display: none;
  position: relative;
  z-index: 2;
  .menu-toggle-button {
    transition: opacity 350ms 0s ease;
    span {
      transition: all 350ms 0s ease;
    }
  }
  @media (min-width: $desktop) {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    transition: all 350ms 0s ease;
    opacity: 0;
    position: relative;
    z-index: 2;
    li {
      margin-right: 16px;
      a {
        display: block;
        color: #fff;
        font-size: 14px;
        padding: 5px 5px 0 5px;
        border-bottom: 1px solid transparent;
        letter-spacing: 1.6px;
        transition: all 250ms 0s ease;
        &:hover {
          text-decoration: none;
          border-color: rgba(#fff, 0.4);
        }
      }
    }
  }
}

#header-navigation-left {
  .menu-toggle-button {
    left: 0;
  }
  ul {
    transform: translateX(-10px);
  }
}
#header-navigation-right {
  .menu-toggle-button {
    right: 0;
  }
  ul {
    transform: translateX(10px);
  }
}

//PROMO

.promo {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;

  &:after {
    content: "";
    display: block;
    z-index: 2;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(#000, 0.5);
  }
}

.promo-content {
  text-align: center;
  position: relative;
  z-index: 3;
  padding-left: 15px;
  padding-right: 15px;
  // margin-top: -25%;
  @media (min-width: $desktop) {
    padding-left: 0;
    padding-right: 0;
    margin-top: 0;
  }
}

.promo-title {
  letter-spacing: 1px;
  padding: 30px 0;
  img {
    height: 85px;
  }
}

.promo-text {
  font-size: 15px;
  line-height: 22px;
  margin-bottom: 20px;
  + .promo-action-button {
    background-color: rgba(#000, 0.25);
  }
  @media (min-width: $desktop) {
    font-size: 18px;
  }
}

.promo-sub-title {
  display: block;
  font-size: 12px;
  letter-spacing: 2px;
  margin-bottom: 5px;
  opacity: 0.8;
  @media (min-width: $desktop) {
    font-size: 16px;
    letter-spacing: 3px;
  }
}

.promo-video {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
  background: url("/assets/img/promo-loader.gif") no-repeat center;
  background-size: 50px auto;
}

.promo-slider {
  .promo-sub-title,
  .promo-title,
  .promo-text,
  .promo-action-button {
    transition-property: opacity, transform;
    transition-duration: 600ms;
    transition-timing-function: ease-out;
    opacity: 0;
    transform: translateY(-40px);
  }

  .promo-title {
    transition-delay: 0.16s;
  }

  .promo-text {
    transition-delay: 0.32s;
  }

  .section {
    height: 100vh;
    &.active {
      .promo-sub-title,
      .promo-title,
      .promo-text,
      .promo-action-button {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
}

.promo-action-button {
  min-width: 135px;
  transition-delay: 0.48s;
}

#fp-nav {
  display: block !important;
  z-index: 10;

  ul {
    li {
      a {
        span {
          background-color: transparent !important;
          border: 1px solid #fff !important;
          width: 5px;
          height: 5px;
        }
      }
    }
  }
}

@keyframes detailPromoIntro {
  0% {
    opacity: 0;
    transform: translateY(-40px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.detail-promo {
  .promo-sub-title,
  .promo-title,
  .promo-action-button {
    animation-name: detailPromoIntro;
    animation-duration: 600ms;
    transition-timing-function: ease-out;
    transition-property: opacity, transform;
    animation-fill-mode: forwards;
    opacity: 0;
  }
  .promo-sub-title {
    animation-delay: 250ms;
  }
  .promo-title {
    animation-delay: 350ms;
  }
  .promo-action-button {
    animation-delay: 450ms;
  }
}

.promo-slide-down {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 80px;
  opacity: 0.5;
  z-index: 5;
  transition: all 350ms 0s ease;
  font-size: 14px;
  letter-spacing: 1px;
  &:hover {
    @media (min-width: $desktop) {
      opacity: 1;
    }
  }
}

@keyframes bounceAlpha {
  0% {
    opacity: 1;
    transform: translateY(0px) scale(1);
  }
  25% {
    opacity: 0;
    transform: translateY(10px) scale(0.9);
  }
  26% {
    opacity: 0;
    transform: translateY(-10px) scale(0.9);
  }
  55% {
    opacity: 1;
    transform: translateY(0px) scale(1);
  }
}

.promo-slide-down-button {
  width: 40px;
  height: 40px;
  font-size: 10px;
  border: 1px solid #fff;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin-top: 5px;
  background-color: transparent;
  .icon {
    &:first-child {
      font-size: 1.2em;
      margin-top: 7px;
      animation-name: bounceAlpha;
      animation-duration: 1.4s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  }
  &:focus,
  &:hover {
    color: #fff;
    outline: none;
  }
}

//-> Welcome Promo

#welcome-promo {
  p {
    b {
      font-size: 14px;
    }
  }
  form {
    padding-top: 5px;
  }
}

#fp-nav {
  @media (max-width: $desktop) {
    display: none !important;
  }
}


.promo-landing-text{
  margin-bottom: 0 !important;
  padding: 30px 0 50px 0;
  letter-spacing: 1px;
}

.promo-landing-title{
  padding: 0 !important;
  margin-bottom: 0 !important;
  font-size: 46px;
}

.promo-companies-sub-title{
  line-height: 1.5em;
  @media (max-width: $desktop) {
    font-size: 15px;
    letter-spacing: 1px;
  }
}

.promo-companies-title {
  font-size: 46px;
}

.promo-companies{
  justify-content: flex-end;
  .promo-content{
    bottom: 160px;
  }
}